.App {
  text-align: center;
}

.header-btn-grp {
  padding: 4px;
}

.app-content {
  margin: 1em auto;
}

.set-input {
  text-align: center;
}

.set-input input {
  max-width: 100px;
  margin: 0 auto;
  text-align: center;
}