.mana-color.mana-r {
  background-color: #db8664;
  color: black;
}
button.btn-primary.mana-color.mana-r.active {
  background-color: #db8664;
  color: black;
}

.mana-color.mana-b {
  background-color: #111;
  color: white;
}
button.btn-primary.mana-color.mana-b.active {
  background-color: #111;
  color: white;
}

.mana-color.mana-u {
  background-color: #4970af;
  color: white;
}
button.btn-primary.mana-color.mana-u.active {
  background-color: #4970af;
  color: white;
}

.mana-color.mana-g {
  background-color: #93b483;
  color: white;
}
button.btn-primary.mana-color.mana-g.active {
  background-color: #93b483;
  color: white;
}

.mana-color.mana-w {
  background-color: #e0d9ca;
  color: black;
}
button.btn-primary.mana-color.mana-w.active {
  background-color: #e0d9ca;
  color: black;
}

.mana-color.mana-m {
  background-color: #dabd6a;
  color: white;
}
button.btn-primary.mana-color.mana-m.active {
  background-color: #dabd6a;
  color: white;
}

.mana-color.mana-c {
  background-color: #87adbb;
  color: white;
}
button.btn-primary.mana-color.mana-c.active {
  background-color: #87adbb;
  color: white;
}

.mana-color.mana-a {
  background-color: #800020;
  color: white;
}
button.btn-primary.mana-color.mana-a.active {
  background-color: #800020;
  color: white;
}

button.mana-color {
  border-color: transparent;
}

.mana-buttons button.btn-primary.mana-color.active {
  border: 2px solid black;
}
