.mtg-card img {
  max-width: 300px;
}

.mtg-card {
  position: relative;
}

.mtg-card .card-action-bar {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}