.rarity-color.rarity-c {
  background-color: black;
  color: white;
}
button.btn-primary.rarity-color.rarity-c.active {
  background-color: black;
  color: white;
}

.rarity-color.rarity-u {
  background-color: silver;
  color: black;
}
button.btn-primary.rarity-color.rarity-u.active {
  background-color: silver;
  color: black;
}

.rarity-color.rarity-r {
  background-color: rgb(189, 160, 0);
  color: black;
}
button.btn-primary.rarity-color.rarity-r.active {
  background-color: rgb(189, 160, 0);
  color: black;
}

.rarity-color.rarity-m {
  background-color: orangered;
  color: white;
}
button.btn-primary.rarity-color.rarity-m.active {
  background-color: orangered;
  color: white;
}

.rarity-icon {
  width: 16px;
}